import { responseParser,targetUrl } from "../../apiService";

function getMeditationAudioJSON() {
  return fetch(targetUrl+"meditation_categories", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getMeditationAudio() {
  return getMeditationAudioJSON();
}

function getMeditationVisualTrackJSON() {
  return fetch(targetUrl+"meditation", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getMeditationVisualTrack() {
  return getMeditationVisualTrackJSON().then(responseParser);
}

function getMeditationBgsJSON() {
  return fetch(targetUrl+"meditation_bg", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getMeditationBgs() {
  return getMeditationBgsJSON();
}

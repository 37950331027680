import React, { Component } from "react";
import YogaItemCard from "../../components/YogaItemCard";
import { Link } from "react-router-dom";
import {FavoritesApi} from "../../apiService";
import Loading from "../../components/Loading";
import i18next from 'i18next';

export default class Favorite extends Component {
  state = {
    title: "List is empty",
    items: [],
    pathName: this.props.match.path.substring(1),
    loading: true
  };
  componentWillMount() {
    this.props.handleTabsVisibility();
    FavoritesApi.getAllYogaItems().then(response => {
      let favoriteIndexes = localStorage.getItem("favorites");
      let favorites = response.filter(item => {
        return favoriteIndexes.includes(item.id);
      });
      this.setState({ items: favorites, loading: false });
      if (favorites.length) {
        this.setState({ title: i18next.t('favorite.title') });
      }else{
        this.setState({ title: i18next.t('empty_list') });
      }
    });
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      content = (
        <div>
          <h1>{this.state.title}</h1>
          <div>
            {this.state.items.map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: `/yoga/${item.id}`,
                    state: { from: this.state.pathName }
                  }}
                  key={index}
                >
                  <YogaItemCard
                    key={index}
                    image={item.yoga_image_b}
                    title={item.yoga_title}
                    subtitle={item.yoga_sanskrit_title}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
    return content;
  }
}

import { responseParser,targetUrl } from "../../apiService";

function getAllYogaItemsJSON() {
  return fetch(targetUrl+"all_yoga", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getAllYogaItems() {
  return getAllYogaItemsJSON().then(responseParser);
}
export function unsubscribe() {
    return fetch("/account.php", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: `action=unsubscribe`
    }).then(resp => {
        return resp.text();
    });
}
export function getDetails() {
    return fetch("/account.php", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: `action=getDetails`
    }).then(resp => {
        return resp.text();
    });
}

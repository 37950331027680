import React, { Component } from "react";
import Slider from "@material-ui/lab/Slider";
import Loading from "../../../components/Loading";
import { MeditationApi } from "../../../apiService";
import { PlayArrow, PauseCircleOutlineTwoTone } from "@material-ui/icons";
import i18next from "i18next";
export default class MeditationAudio extends Component {
  state = {
    items: [],
    nowPlaying: {
      index: 0
    },
    loading: true
  };
  componentWillMount() {
    this.props.handleTabsVisibility();
    let gradients = require('../../../utils/gradients.json');
    MeditationApi.getMeditationAudio().then(response => {
      let parsedData = JSON.parse(response);
      let items = [
        ...parsedData.categories.Aqua,
        ...parsedData.categories.Terra
      ];
      let resArr = items.map(item => {
        item.playing = false;
        item.volume = 20;
        item.initialPlay = false;
        return item;
      });
      this.setState(
        {
          items: resArr
        },
        () => {
          this.setState({ loading: false });
          console.log(this.state.items);
          console.log('grad: ',gradients);
        }
      );
    });
  }
  togglePlayPause(index, audio) {
    let items = this.state.items;
    if (!items[index].initialPlay) {
      audio.volume = items[index].volume/100;
    }
    audio.paused ? audio.play() : audio.pause();
    items[index].playing = !audio.paused;
    this.setState({ items });
    console.log(audio.volume);
  }
  onTogglePlayPause = index => {
    this.togglePlayPause(index, this.refs["audio_" + index]);
  };
  onHandleVolume = (value, item, index) => {
    item.volume = Math.floor(value);
    this.forceUpdate();
    this.refs["audio_" + index].volume = item.volume / 100;
    console.log(this.refs["audio_" + index].volume);
  };
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      content = (
        <div>
          <h1>{i18next.t("meditation_audio.title")}</h1>
          <div className="audio_list">
            {this.state.items.map((item, index) => {
              return (
                <div
                  className="audio_list__item"
                  key={index}
                  onClick={() => {
                    this.onTogglePlayPause(index);
                  }}
                >
                  <div className="audio_list__item__img">
                    <img src={item.icon} alt={item.name + "_icon"} />
                  </div>
                  <div className="audio_list__item__name">{item.name}</div>
                  <div>
                    <audio
                      className="audio"
                      ref={"audio_" + index}
                    >
                      <source src={item.music} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <div className="audio_list__item__volume">
                    <Slider
                      classes={{
                        root:
                          "audio_list__item__volume " +
                          (item.playing ? "enabled" : "")
                      }}
                      value={item.volume}
                      disabled={!item.playing}
                      aria-labelledby="label"
                      onChange={(event, value) => {
                        this.onHandleVolume(value, item, index);
                      }}
                    />
                  </div>
                  <div
                    className={"audio_wall " + (!item.playing ? "visible" : "")}
                    ref={"audio_play_" + index}
                  >
                    <PlayArrow />
                  </div>
                  <div
                    className={"audio_wall " + (item.playing ? "visible" : "")}
                    ref={"audio_pause_" + index}
                  >
                    <PauseCircleOutlineTwoTone />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return content;
  }
}

import React, { Component } from "react";
import Loading from "../../components/Loading";
import LevelsList from "./containers/LevelsList";
import { LevelsApi } from "../../apiService";
import { exactRoute } from "../../routes";

export default class Levels extends Component {
  state = {
    categoriesList: [],
    loading: true
  };
  componentDidMount() {
    this.props.handleTabsVisibility();
    LevelsApi.getLevels().then(response => {
      let sorted = [response[1],response[2],response[0]];
      this.setState({ categoriesList: sorted, loading: false });
    });
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      if (this.props.location.pathname === this.props.match.path) {
        content = <LevelsList 
                  categoriesList={this.state.categoriesList} 
                  {...this.props}/>;
      } else {
        content = (
          <div>
            {this.props.routes.map(route => {
              return exactRoute(this.props, route);
            })}
          </div>
        );
      }
    }
    return content;
  }
}

import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import { Link } from "react-router-dom";
import YogaItemCard from "./YogaItemCard";
import Loading from "./Loading";
import { searchYogas } from "../apiService";
import i18next from 'i18next';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class SearchDialog extends Component {
  state = {
    openDialog: this.props.openSearchDialog,
    query: this.props.query,
    yogaList: [],
    loading: true
  };
  componentWillReceiveProps(nextProps) {
    this.setState({ openDialog: nextProps.openSearchDialog });
    if (this.state.query !== nextProps.query) {
      this.setState(
        {
          query: nextProps.query,
          loading: true
        },
        () => {
          searchYogas(this.state.query).then(response => {
            this.setState({
              yogaList: response,
              loading: false
            });
          });
        }
      );
    }
  }
  handleCloseDialog = () => {
    this.props.onCloseDialog();
    this.setState({ openDialog: false }, () => {
      console.log(this.state);
    });
  };
  render() {
    let content;
    if (this.state.loading) {
      content = content = <Loading type="spin" color="#3f51b5" />;
    } else {
      if (this.state.yogaList.length) {
        content = this.state.yogaList.map((item, index) => {
          return (
            <Link
              to={{
                pathname: `/yoga/${item.id}`,
                state: { from: this.state.pathName }
              }}
              key={index}
              onClick={this.handleCloseDialog}
            >
              <YogaItemCard
                image={item.yoga_image_b}
                title={item.yoga_title}
                subtitle={item.yoga_sanskrit_title}
              />
            </Link>
          );
        });
      } else {
        content = (
          <div className="no_results">
            <div>{i18next.t('on_search')}</div> 
            <strong>{this.state.query}</strong>{" "}
            <div>{i18next.t('nothing_founded')}</div>
          </div>
        );
      }
    }
    return (
      <div>
        <Dialog
          fullScreen
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          TransitionComponent={Transition}
        >
          <AppBar className="app_bar_dialog">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleCloseDialog}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className="dialog_title">
                {this.state.query}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="yoga_list_dialog">{content}</div>
        </Dialog>
      </div>
    );
  }
}

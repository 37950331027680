import React, { Component } from 'react';

export default class TermsAndConditions extends Component{
    componentWillMount(){
        this.props.handleTabsVisibility();
    }
    render(){
        return(
            
            <div className="terms">
                <h1>Terms & Conditions on local Language</h1>
            </div>
        )
    }
}
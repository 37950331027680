import * as CategoriesApi from "./modules/Categories/api";
import * as FavoritesApi from "./modules/Favorite/api";
import * as LevelsApi from "./modules/Levels/api";
import * as MeditationApi from "./modules/Meditation/api";
import * as SalutationApi from "./modules/SunSalutation/api";
import {existingLangs,interLangCountries} from "./utils/countrySettings";

let lang = localStorage.getItem("lang"),
  targetUrl;


lang = lang && existingLangs.includes(lang) ? lang : "";
if(interLangCountries.includes(lang)){
  lang = "";
}
targetUrl = "http://yogaforyou.club/" + lang + "/api.php?";
// targetUrl = lang + "/api.php?";

export function responseParser(responseText) {
  let response = JSON.parse(responseText);
  return new Promise((resolve, reject) => {
    if (response.hasOwnProperty("YOGA_APP")) {
      resolve(response.YOGA_APP);
    }
  });
}

function searchYogasJSON(query) {
  return fetch(`${targetUrl}search=${query}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function searchYogas(query) {
  return searchYogasJSON(query).then(responseParser);
}

export {
  targetUrl,
  CategoriesApi,
  FavoritesApi,
  LevelsApi,
  MeditationApi,
  SalutationApi
};

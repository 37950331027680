import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { SalutationApi } from "../../apiService";
import Loading from "../../components/Loading";
import i18next from "i18next";

function TabContainer({ children, dir }) {
  return (
    <div component="div" dir={dir} className="tabs__item">
      <div className="tabs__item__card">{children}</div>
    </div>
  );
}

export default class SunSalutation extends Component {
  state = {
    steps: [],
    loading: true
  };
  componentDidMount() {
    this.props.handleTabsVisibility();
    SalutationApi.getSalutationItems().then(response => {
      this.setState({ steps: response, loading: false });
    });
  }
  interpolateHTML(html) {
    return { __html: html };
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      content = (
        <div>
        <h1 style={{marginBottom: 0,paddingLeft: 5}}>{i18next.t('salutation.title')}</h1>
        <SwipeableViews
          axis={"x"}
        >
          {this.state.steps.map((item, index) => {
            return (
              <TabContainer dir={"ltr"} key={index}>
                <div>
                    <h3>{item.step_name}</h3>
                </div>
                <div>
                    <img src={item.step_image} alt={item.step_name+'_img'}/>
                </div>
                <div
                  dangerouslySetInnerHTML={this.interpolateHTML(
                    item.step_info
                  )}
                />
              </TabContainer>
            );
          })}
        </SwipeableViews>
      </div>
      )
    }
    return content;
  }
}

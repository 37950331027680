import React, { Component } from "react";
import Header from "./components/header";
import SideDrawer from "./components/drawer";
import { Switch,withRouter } from "react-router-dom";

import SearchDialog from "./components/SearchDialog";
import SubscrModal from "./components/SubscriptionModal";
import InstantSubscrModal from "./components/InstantSubsModal";
import {RouteWithSubRoutes, routes} from "./routes";
import i18next from 'i18next';
import {existingLangs, rejectedLangs, interLangCountries} from "./utils/countrySettings";
import { setCookie } from "./utils/countrySettings"
import {changeLang} from "./actions/langActions"
import { connect } from "react-redux"
import "./App.css";

class App extends Component {
  state = {
    drawerVisibility: false,
    drawerItemsInLang: [],
    tabsVisibility: false,
    tabsValue: 0,
    openSearchDialog: false,
    searchQuery: '',
    openSubscriptionModal: false
  };

  setAuth(location){
    if(!rejectedLangs.includes(location)){
      setCookie('auth','true',{expires: 172800})
    }
  }

  defineLocation(langs){
    let url = window.location.pathname;
    let founded = false;
    let lang = '';
    for(let i = 0; i < langs.length; i++){
      if(url.includes('/'+langs[i]+'/')){
        founded = true;
        lang = langs[i];
        console.log(langs[i],url,url.includes('/'+langs[i]));
      }
    }
    if(founded){
      console.log('lang',lang,'was founded');
      return lang;
    }else{
      console.log('in url langs weren`t founded');
      return null;
    }
  }

  setFavorites(){
    if (!localStorage.getItem("favorites")) {
      let ids = [];
      localStorage.setItem("favorites", JSON.stringify(ids));
    } else {
      console.log("favorites: ", JSON.parse(localStorage.getItem("favorites")));
    }
  }

  setLanguage(language) {
    if(existingLangs.includes(language) && !interLangCountries.includes(language)){
      i18next.init({
        lng: language,
        resources: require('./lang/'+language+'.json')
      });
      this.setState({drawerItemsInLang: i18next.store.data[localStorage.getItem('lang')].translation.drawer_items})
    }else{
      console.log(language,'wasn`t found, now using "en"');
      i18next.init({
        lng: 'en',
        resources: require('./lang/en.json')
      });
      this.setState({drawerItemsInLang: i18next.store.data['en'].translation.drawer_items})
    } 
  }

  setLocation(){
    let definedLang = this.defineLocation(existingLangs);
    if(definedLang){
      localStorage.setItem('lang',definedLang);
      let newPath = window.location.pathname;
      window.location.pathname = newPath.replace('/'+definedLang,'');
    }else{
      if(!localStorage.getItem('lang')){
        console.log('lang isn`t defined');
        localStorage.setItem('lang','en');
        this.setLanguage('en');
        this.props.dispatch(changeLang('en'));
      }else{
        this.setLanguage(localStorage.getItem('lang'));
        this.props.dispatch(changeLang(localStorage.getItem('lang')));
      }
    }
  }
  
  componentDidMount() {
    this.setFavorites();
    this.setLocation();
    this.setAuth(localStorage.getItem('lang'))
  }
  toggleDrawer = open => () => {
    this.setState({ drawerVisibility: open });
  };
  toggleTabs = open => () => {
    this.setState({ tabsVisibility: open });
  };
  tabsChangeIndex = index => {
    this.setState({ tabsValue: index });
  };
  changeTabs = (event, value) => {
    this.setState({ tabsValue: value });
  };
  openSearchDialog = (query) => {
    this.setState({searchQuery: query, openSearchDialog:true});
  }
  closeSearchDialog = () => {
    this.setState({openSearchDialog:false});
  }
  onToggleModal = (state) =>{
    this.setState({
      openSubscriptionModal: state || !this.state.openSubscriptionModal
    });
  }
  render() {
    console.log('test: ',this.props.test)
    return (
      <div className="App">
        <Header
          handleDrawer={this.toggleDrawer(!this.state.drawerVisibility)}
          tabsVisibility={this.state.tabsVisibility}
          tabsValue={this.state.tabsValue}
          handleChangeTabs={this.changeTabs}
          onSearch={this.openSearchDialog}
        />
        <SideDrawer
          items={this.state.drawerItemsInLang}
          open={this.state.drawerVisibility}
          hide={this.toggleDrawer(false)}
          openSubscriptionModal={this.onToggleModal}
        />
        <main
          className={
            "main desktop_width " + 
            (window.location.href.includes("visual") ? "visual" : "") +
            (localStorage.getItem('lang') === "ksa" ? "rtl" : "")
          }
        >
          <Switch>
            {routes.map(route => (
              <RouteWithSubRoutes
                key={route.path}
                handleTabsVisibility={this.toggleTabs(route.enableTabs)}
                tabsValue={this.state.tabsValue}
                handleChangeIndex={this.tabsChangeIndex}
                openSubscriptionModal={this.onToggleModal}
                {...route}
              />
            ))}
          </Switch>
          <SearchDialog 
          openSearchDialog={this.state.openSearchDialog}
          query={this.state.searchQuery} 
          onCloseDialog={this.closeSearchDialog}/>
          {localStorage.getItem('lang') === 'xl' 
          ? <InstantSubscrModal 
          open={this.state.openSubscriptionModal}
          toggleModal={this.onToggleModal} />
          : <SubscrModal 
          open={this.state.openSubscriptionModal}
          toggleModal={this.onToggleModal}/>}
        </main>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    test: state
  }
}

export default withRouter(
  connect(mapStateToProps)(App)
)


import { responseParser, targetUrl } from "../../apiService";

function getCategoriesJSON() {
  return fetch(targetUrl+"cat_list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getCategories() {
  return getCategoriesJSON().then(responseParser);
}

function getCategoryDetailsJSON(id) {
  return fetch(`${targetUrl}cat_id=${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getCategoryDetails(id) {
  return getCategoryDetailsJSON(id).then(responseParser);
}

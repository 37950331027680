export const existingLangs = ["es", "pt", "pl", "id", "en", "no", "hr","se","my","ksa","xl","bh"];
export const rejectedLangs = ["id","en","xl","bh", "pl","es"];
export const interLangCountries = ["en","id","my","xl","bh"]

export const preventIfNoneSubscription = event => {
  if (!document.cookie.includes('auth')) {
    event.preventDefault();
    if(localStorage.getItem('lang') === 'pl'){
      window.location.href = '/pl/pl-land/redirect.php';
      return false;
    } else if (localStorage.getItem('lang') === 'es') {
      window.location.href = '/es/es-land/redirect.php?source=portal';
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export function setCookie(name, value, options) {
  options = options || {};

  let expires = options.expires;

  if (typeof expires == "number" && expires) {
    let d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = name + "=" + value;

  for (let propName in options) {
    updatedCookie += "; " + propName;
    let propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import {history} from "../../index"

import {unsubscribe,getDetails} from "./api"

export default class User extends Component {
  state = {
    details: null,
    message: ''
  }
  componentDidMount(){
    getDetails().then(resp => {
      console.log('resp',JSON.parse(resp))
      this.setState({details: JSON.parse(resp).details})
    })
    .catch(err => {
      console.log('failed to load')
    })
  }
  onUnsubscribe = () => {
    if(localStorage.getItem('lang') === 'bh'){
      unsubscribe()
        .then((resp)=>{
          console.log('resp',resp)
          this.setState({message: JSON.parse(resp).text})
          history.push('/levels')
        })
        .catch((err)=>{
          console.log('error',err)
        })
    }
  }
  render() {
    let content, unsubscribeButton;
    console.log(localStorage.getItem("lang"));
    if (localStorage.getItem("lang") !== "my") {
      content = (
        <div>
          <div className="account_card">
            <Card>
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>User id:</div>
                  <div>
                    {this.state.details && this.state.details.account_name}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="account_card">
            <h4>My Subscription</h4>
            <h5>{this.state.message}</h5>
            <div>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography>
                      {this.state.details && 'Yoga For You subscription ' +this.state.details.subscribe_date}
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    {/* <Typography>
                      Yoga For You subscription 3300 IDR made on 28.11.2018
                    </Typography> */}
                    <Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <div style={{ fontWeight: "bold", marginRight: 10 }}>
                          Status:{" "}
                        </div>
                        <div> Active</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <div style={{ fontWeight: "bold", marginRight: 10 }}>
                          Valid Until:{" "}
                        </div>
                        <div>{this.state.details && this.state.details.expire_date}</div>
                      </div>
                      <div>
                        <Button variant="contained" color="primary">
                          STOP
                        </Button>
                      </div>
                    </Typography>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </div>
        </div>
      );
      unsubscribeButton = (
        <Button variant="outlined" color="primary" onClick={this.onUnsubscribe}>
          UNSUBSCRIBE
        </Button>
      );
    } else {
      content = (
        <div>
          <div className="account_card">
            <Card>
              <CardContent>
                <div>
                  <p>
                    To unsubscribe please click below button. This option is
                    available only for Celcom users
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      );
      unsubscribeButton = (
        <a href="http://yogaforyou.club/my/my4-land/unsubscribe.php">
          <Button variant="outlined" color="primary">
            UNSUBSCRIBE
          </Button>
        </a>
      );
    }
    if (localStorage.getItem("lang") === "xl") {
      content = (
        <Card>
          <CardContent>
            <div>
            To unsubscribe please send UNSUB YOGA to 99876. Need help? Call 02152902182 or email to customer.service@linkit360.com
            </div>
          </CardContent>
        </Card>
      );
      unsubscribeButton = ''
    }
    return (
      <div>
        {localStorage.getItem("lang") === "xl" ? <h1>Unsubscribe</h1> : <h1>My Account</h1>}
        {content}
        <div style={{ textAlign: "center" }}>{unsubscribeButton}</div>
      </div>
    );
  }
}

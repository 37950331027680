import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LevelsApi } from "../../../apiService";
import YogaItemCard from "../../../components/YogaItemCard";
import Loading from "../../../components/Loading";

class LevelDetails extends Component {
  state = {
    title: "Level Details",
    levelId: "",
    items: [],
    pathName: this.props.match.path.substring(1),
    loading: true
  };
  componentDidMount() {
    const levelId = this.props.match.params.id;
    Promise.all([
      LevelsApi.getLevels(),
      LevelsApi.getLevelDetails(levelId)
    ]).then(response => {
      if (!response[1].length) {
        this.props.history.push("/levels");
      } else {
        let title = response[0].find(item => item.lid === levelId).level_name;
        if (title) {
          this.setState({ title, levelId });
        }
        if (response[1].length) {
          this.setState({ items: response[1] });
        }
        this.setState({ loading: false });
      }
    });
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      content = (
        <div>
          <h1>{this.state.title}</h1>
          <div className="level_details">
            {this.state.items.map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: `/yoga/${item.id}`,
                    state: { from: this.state.pathName }
                  }}
                  key={index}
                >
                  <YogaItemCard
                    image={item.yoga_image_b}
                    title={item.yoga_title}
                    subtitle={item.yoga_sanskrit_title}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
    return content;
  }
}

export default LevelDetails

import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// import Modal from "@material-ui/core/Modal";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import Button from "@material-ui/core/Button";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import i18next from "i18next";
import {
  Tune,
  Category,
  FavoriteBorder,
  Person,
  MusicNote,
  LocalFloristOutlined,
  WbSunny,
  InfoOutlined,
  // Language,
  SupervisedUserCircle
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { preventIfNoneSubscription } from "../utils/countrySettings";
// import SubscrModal from "./SubscriptionModal";

const existingTerms = ["pl", "en", "id", "hr", "no", "se", "my", "ksa"];

const drawerItems = [
  {
    title: "Yoga levels",
    icon: <Tune />,
    url: "/levels",
    for: "all"
  },
  {
    title: "Categories",
    icon: <Category />,
    url: "/categories",
    for: "all"
  },
  {
    title: "Favorite",
    icon: <FavoriteBorder />,
    url: "/favorite",
    for: "all"
  },
  {
    title: "Meditation",
    icon: <SupervisedUserCircle />,
    url: "/meditation/visual",
    for: "all"
  },
  {
    title: "Meditation music",
    icon: <MusicNote />,
    url: "/meditation/audio",
    for: "all"
  },
  {
    title: "Yoga Routine",
    icon: <LocalFloristOutlined />,
    url: "/routine",
    for: "all"
  },
  {
    title: "Sun Salutation",
    icon: <WbSunny />,
    url: "/salutation",
    for: "all"
  },
  {
    title: "My Account",
    icon: <Person />,
    url: "/account",
    for: ["id","bh"]
  },
  {
    title: "Unsubscribe",
    icon: <Person />,
    url: "/account",
    for: ["my", "xl"]
  }
];

export default class SideDrawer extends Component {
  state = {
    visibility: this.props.open,
    openModal: false,
    langValue: localStorage.getItem("lang") || "en",
    itemsInLang: this.props.items,
    openSubscrModal: false
  };

  _setItems() {
    drawerItems.forEach(item => {
      let key = item.url.substring(1).replace("/", "_");
      if (this.state.itemsInLang.hasOwnProperty(key)) {
        item.title = this.state.itemsInLang[key];
      }
      if (!item.hasOwnProperty("for")) {
        item.for = "all";
      }
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.itemsInLang !== nextProps.items) {
      nextState.itemsInLang = nextProps.items;
      this._setItems();
    }
    nextState.visibility = nextProps.open;
  }
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  handleChange = event => {
    this.setState({ langValue: event.target.value });
  };
  onPrevent = (event, item) => {
    if (!document.cookie.includes("auth") && item.url !== "/account") {
      this.props.openSubscriptionModal(true);
      preventIfNoneSubscription(event);
    }
    this.props.hide();
  };
  toggleModal = state => {
    this.setState({ openSubscrModal: state });
  };
  render() {
    let termsLink = "";
    if (existingTerms.includes(localStorage.getItem("lang"))) {
      termsLink = (
        <a
          href={
            "http://yogaforyou.club/" +
            localStorage.getItem("lang") +
            "/terms.php"
          }
        >
          <div className="drawer__item">
            <div className="drawer__item__icon">
              <InfoOutlined />
            </div>
            <div className="drawer__item__text">
              {i18next.t("drawer_items.terms")}
            </div>
          </div>
        </a>
      );
    } else {
      termsLink = (
        <NavLink to="/terms" onClick={this.props.hide}>
          <div className="drawer__item">
            <div className="drawer__item__icon">
              <InfoOutlined />
            </div>
            <div className="drawer__item__text">
              {i18next.t("drawer_items.terms")}
            </div>
          </div>
        </NavLink>
      );
    }
    const sideList = (
      <div
        className={"drawer " + (this.state.langValue === "ksa" ? "rtl" : "")}
      >
        <div className="">
          <NavLink
            to="/levels"
            onClick={this.props.hide}
            className="link drawer__head"
          >
            <div className="drawer__head__logo">
              <img
                src="http://yogaforyou.club/static/images/logo_black.png"
                alt="logo"
              />
            </div>
          </NavLink>
        </div>
        <List>
          <div>
            {drawerItems.map((item, index) => {
              if (
                item.for === "all" ||
                item.for.includes(localStorage.getItem("lang"))
              ) {
                return (
                  <NavLink
                    exact
                    to={item.url}
                    className="link"
                    activeClassName="active_link"
                    onClick={event => {
                      this.onPrevent(event, item);
                    }}
                    key={index}
                  >
                    <div className="drawer__item">
                      <div className="drawer__item__icon">{item.icon}</div>
                      <div className="drawer__item__text">{item.title}</div>
                    </div>
                  </NavLink>
                );
              } else {
                return "";
              }
            })}
            {termsLink}
            {localStorage.getItem("lang") !== "xl" && localStorage.getItem("lang") !== "en" && (
              <a href="https://play.google.com/store/apps/details?id=com.sfbtech.yoga">
                <div style={{ marginLeft: "-11px" }} className="drawer__item">
                  <img
                    style={{ maxWidth: "200px" }}
                    src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                    alt="google_play_badge"
                  />
                </div>
              </a>
            )}
          </div>
        </List>
      </div>
    );
    return (
      <Drawer open={this.state.visibility} onClose={this.props.hide}>
        {sideList}
      </Drawer>
    );
  }
}

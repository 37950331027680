import React, { Component } from "react";
import Slider from "react-slick";
import {MeditationApi} from "../../../apiService";
import Loading from "../../../components/Loading";
import { PlayArrow, PauseCircleOutlineTwoTone } from "@material-ui/icons";
import i18next from 'i18next';

export default class MeditationVisual extends Component {
  state = {
    audio: "",
    backgrounds: [],
    settings: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    nav1: null,
    nav2: null,
    loading: true,
    navVisibility: true,
    audioPlaying: false
  };
  componentDidMount() {
    this.props.handleTabsVisibility();
    Promise.all([MeditationApi.getMeditationVisualTrack(), MeditationApi.getMeditationBgs()]).then(
      response => {
        let parsedData = JSON.parse(response[1]).links,
          parsedResp = response[0];
        this.setState({
          audio: parsedResp[0].mp3_url,
          backgrounds: parsedData,
          nav1: this.slider,
          nav2: this.navigation,
          loading: false
        });
      }
    );
  }
  handleTouch = index => {
    this.slider.slickGoTo(index);
  };
  handleNavigaion = () => {
    this.setState({ navVisibility: !this.state.navVisibility });
  };
  togglePlayPause = () => {
    let track = this.refs["audio_track"];
    track.paused ? track.play() : track.pause();
    this.setState({audioPlaying: !track.paused});
  };
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      let title, navigation, playButton;
      if (this.state.navVisibility) {
        if (!this.state.audioPlaying) {
          playButton = <PlayArrow />;
        } else {
          playButton = <PauseCircleOutlineTwoTone />;
        }
        title = (
          <div className="meditation_title">
            <h1>{i18next.t('meditation_visual.title')}</h1>
          </div>
        );
        navigation = (
          <div className="meditation_navigation">
            <div
              className="meditation_navigation__play"
              onClick={this.togglePlayPause}
            >
              {playButton}
            </div>
            <Slider
              asNavFor={this.state.nav1}
              ref={navigation => (this.navigation = navigation)}
              slidesToShow={3}
              infinite={true}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {this.state.backgrounds.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="meditation_navigation_item"
                    onClick={() => {
                      this.handleTouch(index);
                    }}
                  >
                    <div
                      className="meditation_navigation_item_wall"
                      style={{ backgroundImage: "url(" + item + ")" }}
                    >
                      <img src={item} style={{ visibility: "hidden" }} alt={item+'_img'}/>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        );
      }
      content = (
        <div className="meditation_main_space">
          {title}
          <audio ref={"audio_track"}>
            <source src={this.state.audio} type="audio/mpeg" />
          </audio>
          <Slider
            {...this.state.settings}
            asNavFor={this.state.nav1}
            swipeToSlide={true}
            focusOnSelect={true}
            ref={slider => {
              this.slider = slider;
            }}
          >
            {this.state.backgrounds.map((item, index) => {
              return (
                <div key={index} className="meditation_visual">
                  <div
                    onClick={this.handleNavigaion}
                    style={{ backgroundImage: "url(" + item + ")" }}
                    className="meditation_visual__wall"
                  >
                    <img src={item} style={{ visibility: "hidden" }} alt={item+'_img'}/>
                  </div>
                </div>
              );
            })}
          </Slider>
          {navigation}
        </div>
      );
    }
    return content;
  }
}

import Account from "./index"

export default [
  {
    path: "/account",
    component: Account,
    allowed: ["id","my","xl","bh"],
    enableTabs: false
  }
];

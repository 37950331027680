import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Clear } from "@material-ui/icons";
import { setCookie } from "../utils/countrySettings";
import { history } from "../index";
import TextField from "@material-ui/core/TextField";
import { postAuth } from "../modules/Auth/api";

export default class InstantSubsnModal extends Component {
  state = {
    open: this.props.open,
    loginInput: "",
    passInput: "",
    valid: true,
    disabled: false
  };
  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }
  closeModal = () => {
    this.props.toggleModal(false);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  checkAuth = () => {
    this.setState({ disabled: true });
    let params = {
      login: this.state.loginInput,
      pass: this.state.passInput
    };
    postAuth(params).then(response => {
      const ans = JSON.parse(response);
      if (ans.status === "error") {
        this.setState({ valid: false });
      }
      if (ans.status === "success") {
        setCookie("auth", "true", { expires: 172800 });
        this.closeModal()
        this.setState({ valid: true });
      }
      this.setState({ disabled: false });
    });
  };

  render() {
    let message;
    if (!this.state.valid) {
      message = <div style={{ color: "red" }}>Invalid login or password</div>;
    }
    return (
      <div>
        <Modal open={this.state.open} onClose={this.closeModal}>
          <div className="modal">
            <div className="modal_close">
              CLOSE
              <div onClick={this.closeModal} className="modal_close__btn">
                <Clear />
              </div>
            </div>
            <div className="modal__body subscr_modal__body">
              <h4>Masuk untuk menikmati konten-konten dari Yoga For You</h4>
              
                <TextField
                  onChange={this.handleChange("loginInput")}
                  id="filled-email-input"
                  label="Username"
                  type="text"
                  name="username"
                  margin="normal"
                  variant="filled"
                  style={{ width: "100%" }}
                />
              
              
                <TextField
                  onChange={this.handleChange("passInput")}
                  id="filled-password-input"
                  label="Password"
                  type="password"
                  name="password"
                  margin="normal"
                  variant="filled"
                  style={{ width: "100%" }}
                />
                <div srtle={{marginBottom: 10}}>{message}</div>
                <Button onClick={this.checkAuth} disabled={this.state.disabled} variant="contained" color="secondary">Login</Button>
                <h5>Don't have an account?</h5>
                <div style={{marginBottom: 15}}><Button variant="contained" color="primary">BERLANGGANAN</Button></div>
                <div><Button variant="contained">SEKALI BELI-</Button></div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default function reducer(state = {
    lang: 'en'    
},action){
    switch (action.type){
        case 'CHANGE_LANG': {
            console.log('reduser dispatched')
            return {...state, lang: action.payload}
        }
        default: return state
    }
}
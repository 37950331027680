import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { setCookie } from "../../utils/countrySettings";
import { history } from "../../index";
import { postAuth } from "./api";

export default class Auth extends Component {
  state = {
    loginInput: "",
    passInput: "",
    valid: true,
    disabled: false
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  checkAuth = () => {
    this.setState({ disabled: true });
    let params = {
      login: this.state.loginInput,
      pass: this.state.passInput
    };
    postAuth(params)
      .then(response => {
      const ans = JSON.parse(response);
      if (ans.status === "error") {
        this.setState({ valid: false });
      }
      if (ans.status === "success") {
        setCookie("auth", "true",{expires: 172800});
        history.push("/");
        this.setState({ valid: true });
      }
      this.setState({ disabled: false });
    })
    .catch(err => {
      this.setState({valid: false})
    });
  };
  render() {
    let message;
    let xlTitle = <h1>Masuk untuk menikmati konten-konten dari Yoga For You</h1>,
        xlActions
    if (!this.state.valid) {
      message = <div style={{ color: "red" }}> Something went wrong. Please check login or password</div>;
    }
    if(localStorage.getItem('lang') === 'xl'){
      xlActions = (
        <div>
          <h3>Don't have account? </h3>
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "200px", marginBottom: "10px" }}>
              BERLANGGANAN
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ width: "200px" }}>
              SEKALI BELI-
            </Button>
          </div>
        </div>
      )
    }
    return (
      <div>
        {localStorage.getItem('lang') === 'xl' ? xlTitle : <h1>Auth</h1>}
        <div>
          <div>
            <TextField
              onChange={this.handleChange("loginInput")}
              id="filled-email-input"
              label="Username"
              type="text"
              name="username"
              margin="normal"
              variant="filled"
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <TextField
              onChange={this.handleChange("passInput")}
              id="filled-password-input"
              label="Password"
              type="password"
              name="password"
              margin="normal"
              variant="filled"
              style={{ width: "100%" }}
            />
          </div>
          {message}
          <div style={{ textAlign: "center", paddingTop: "20px" }}>
            <Button
              disabled={this.state.disabled}
              variant="contained"
              color="secondary"
              style={{ width: "200px" }}
              onClick={this.checkAuth}
            >
              Login
            </Button>
          </div>
          {xlActions}
        </div>
      </div>
    );
  }
}

import { responseParser,targetUrl } from "../../apiService";

function getLevelsJSON() {
  return fetch(targetUrl+"level_list", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getLevels() {
  return getLevelsJSON().then(responseParser);
}

function getLevelDetailsJSON(id) {
  return fetch(`${targetUrl}level_id=${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getLevelDetails(id) {
  return getLevelDetailsJSON(id).then(responseParser);
}

function getYogaDetailsJSON(id) {
    return fetch(`${targetUrl}yoga_id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then(response => {
      return response.text();
    });
  }
  
  export function getYogaDetails(id) {
    return getYogaDetailsJSON(id).then(responseParser);
  }

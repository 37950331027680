import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import {preventIfNoneSubscription} from "../../../utils/countrySettings";

export default class LevelsList extends Component {
  state = {
    openModal: false
  }
  toggleModal = (state) =>{
    this.setState({openModal: state});
  }
  onPrevent = (event) =>{
    if(preventIfNoneSubscription(event)){
      this.props.openSubscriptionModal(true);
    }
  }
  render() {
    return (
      <div>
        <h1>{i18next.t('levels.title')}</h1>
        <div className="levels">
          {this.props.categoriesList.map((item, index) => {
            return (
              <Link to={`/levels/${item.lid}`} key={index} onClick={this.onPrevent}>
                <div className="levels__item">
                  <div className="levels__item__img">
                    <img 
                    src={item.level_image_b} 
                    alt={item.level_name+'_img'}/>
                  </div>
                  <div className="levels__item__title">{item.level_name}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CategoriesApi } from "../../../apiService";
import YogaItemCard from "../../../components/YogaItemCard";
import Loading from "../../../components/Loading";

export default class CategoryDetails extends Component {
  state = {
    title: "Category Details",
    catId: "",
    items: [],
    loading: true,
    pathName: this.props.match.path
      .substring(1)
      .substring(0, this.props.match.path.substring(1).indexOf("/"))
  };
  componentDidMount() {
    const catId = this.props.match.params.id;
    Promise.all([
      CategoriesApi.getCategories(),
      CategoriesApi.getCategoryDetails(catId)
    ]).then(response => {
      if (!response[1].length) {
        this.props.history.push("/categories");
      } else {
        let title = response[0].find(item => item.cid === catId).category_name;
        if (title) {
          this.setState({ title, catId });
        }
        if (response[1].length) {
          this.setState({ items: response[1] });
        }
        this.setState({ loading: false });
      }
    });
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      content = (
        <div>
          <h1>{this.state.title}</h1>
          <div className="category_details">
            {this.state.items.map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: `/yoga/${item.id}`,
                    state: { from: this.state.pathName }
                  }}
                  key={index}
                >
                  <YogaItemCard
                    image={item.yoga_image_b}
                    title={item.yoga_title}
                    subtitle={item.yoga_sanskrit_title}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
    return content;
  }
}

import Categories from "./index";
import CategoryDetails from "./containers/CategoryDetails";

export default [
  {
    path: "/categories",
    component: Categories,
    enableTabs: false,
    routes: [
        {
            path: '/categories/:id',
            component: CategoryDetails,
        }
    ]
  }
]

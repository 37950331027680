import { responseParser,targetUrl } from "../../apiService";

function getSalutationItemsJSON() {
  return fetch(targetUrl+"sun_salutation", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response.text();
  });
}

export function getSalutationItems() {
  return getSalutationItemsJSON().then(responseParser);
}
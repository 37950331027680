import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import {preventIfNoneSubscription} from "../../../utils/countrySettings";

export default class CategoriesList extends Component {
  state = {
    openModal: false
  }
  toggleModal = (state) =>{
    this.setState({openModal: state});
  }
  onPrevent = (event) =>{
    if(preventIfNoneSubscription(event)){
      this.props.openSubscriptionModal(true);
    }
  }
  render() {
    return (
      <div>
        <h1>{i18next.t('categories.title')}</h1>
        <div className="categories_list">
          {this.props.categories.map((item, index) => {
            return (
              <Link
                to={`/categories/${item.cid}`}
                key={index}
                className="categories_list__item"
                onClick={this.onPrevent}
              >
                <div>
                  <div className="categories_list__item__img">
                    <img 
                    src={item.category_image_b}
                    alt={item.category_name+'_img'} />
                  </div>
                  <div className="categories_list__item__title">
                    {item.category_name}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Clear } from "@material-ui/icons";

const customCountries = ['en','bh']

export default class SubscriptionModal extends Component {
  state = {
    open: this.props.open
  };
  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }
  closeModal = () => {
    this.props.toggleModal(false);
  };
  render() {
    return (
      <div>
        <Modal open={this.state.open} onClose={this.closeModal}>
          <div className="modal">
            <div className="modal_close">
              CLOSE
              <div onClick={this.closeModal} className="modal_close__btn">
                <Clear />
              </div>
            </div>
            <div className="modal__body subscr_modal__body">
              <h4>SUBSCRIBE TO ENJOY THE SERVICE!</h4>
              <div className="modal__body__img">
                <img
                  src="https://mbtskoudsalg.com/images/free-yoga-symbol-png-6.png"
                  alt="logo"
                />
              </div>
              <p style={{ margin: "20px" }}>
                {localStorage.getItem("lang") === "en" && (
                  <div>
                    <p>Subscription costs:</p>
                    <p>AED 11/week for Etisalat users</p>
                    <p>AED 9/week for DU users</p>
                  </div>
                )}
                {localStorage.getItem("lang") === "bh" && (
                  <div>
                    <p>BHD 0.5/week for Viva users</p>    
                    <p>BHD 0.5/week for Batelco users</p>
                    <p>BHD 0.5/week for Zain users</p>
                  </div>
                )}
                {!customCountries.includes(localStorage.getItem("lang")) && (
                  <p>Only Rp. 2000 (exc. tax) 3 times a week</p>
                )}
              </p>
            </div>
            <div className="modal__footer">
              <div className="modal__footer__title">Already a member?</div>
              <NavLink onClick={this.closeModal} to="/auth">
                <Button variant="contained" color="secondary">
                  Sign in
                </Button>
              </NavLink>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

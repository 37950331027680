import React, { Component } from "react";
import CatList from "./containers/CategoriesList";
import { CategoriesApi } from "../../apiService";
import Loading from "../../components/Loading";
import { exactRoute } from "../../routes";

export default class Categories extends Component {
  state = {
    categories: [],
    loading: true
  };
  componentDidMount() {
    this.props.handleTabsVisibility();
    console.log(this.props)
    CategoriesApi.getCategories().then(response => {
      this.setState({ categories: response, loading: false });
    });
  }
  render() {
    let content;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      if(this.props.location.pathname === this.props.match.path){
        content = (
          <CatList 
          categories={this.state.categories} 
          {...this.props}
          />
        )
      }else{
        content = (
          <div>
            {this.props.routes.map(route => {
              return exactRoute(this.props,route);
            })}
          </div>
        );
      }
    }
    return content;
  }
}

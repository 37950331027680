import Levels from "./index";
import LevelDetails from "./containers/LevelDetails";

export default [
  {
    path: "/levels",
    component: Levels,
    enableTabs: false,
    routes: [
        {
            path: '/levels/:id',
            component: LevelDetails
        }
    ]
  }
]

import React, { Component } from "react";
import Checkbox from '@material-ui/core/Checkbox';

export default class YogaItemCard extends Component {
  state = {
    checked: this.props.checked
  }
  handleChange = checked => event => {
    this.props.onChange(event.target.checked,this.props.id,this.props.data);
    this.setState({ [checked]: event.target.checked });
  };
  render() {
    let checkbox;
    if(this.props.checkbox){
      checkbox = (
        <div className="yoga_card_item__checkbox">
        <Checkbox
          checked={this.state.checked}
          onChange={this.handleChange('checked')}
          value="checked"
          color="primary"
        />
        </div>
      )
    }
    return (
      <div className="yoga_card_item">
        <div className="yoga_card_item__img">
          <img 
            src={this.props.image}
            alt={this.props.title+'_img'}
           />
        </div>
        <div className="yoga_card_item__body">
          <div className="yoga_card_item__title">
            {this.props.title}
          </div>
          <div className="yoga_card_item__subtitle">
            {this.props.subtitle}
          </div>
        </div>
        {checkbox}
      </div>
    );
  }
}

import React, { Component } from "react";
import { Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import YogaItemCard from "../../components/YogaItemCard";
import { FavoritesApi } from "../../apiService";
import i18next from "i18next";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Routine extends Component {
  state = {
    openModal: false,
    openDialog: false,
    newItem: undefined,
    items: [],
    selected: {},
    yogaList: [],
    choosedYogas: []
  };
  componentWillMount() {
    this.props.handleTabsVisibility();
    FavoritesApi.getAllYogaItems().then(response => {
      let list = response.map(item => {
        item.checked = false;
        return item;
      });
      this.setState({ yogaList: list });
    });
    let items = [];
    for (let key in localStorage) {
      if (key.includes("routine-")) {
        let temp = {
          title: key.substring(key.indexOf("-") + 1, key.length),
          selectedYogas: JSON.parse(localStorage.getItem(key))
        };
        items.push(temp);
      }
    }
    this.setState({ items });
  }
  openModal = () => {
    this.setState({ openModal: true });
  };
  createNewItem = () => {
    if (!this.state.newItem.length) {
      return;
    }
    let temp = {
      title: this.state.newItem,
      selectedYogas: []
    };
    let curState = this.state.items;
    this.setState({ items: [...curState, temp] });
    this.handleCloseModal();
    this.setState({ newItem: "" });
    localStorage.setItem("routine-" + temp.title, JSON.stringify([]));
  };
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  handleOpenDialog = item => {
    item.tempSelected = [...item.selectedYogas];
    this.setState({
      openDialog: true,
      selected: item
    });
    let tempList = this.state.yogaList;
    item.tempSelected.forEach(item => {
      let index = tempList.findIndex(fItem => fItem.id === item);
      tempList[index].checked = true;
    });
    this.setState({ yogaList: tempList });
  };
  handleCloseDialog = () => {
    let temp = [...this.state.yogaList];
    this.setState({
      openDialog: false,
      yogaList: temp.map(item => {
        item.checked = false;
        return item;
      })
    });
  };
  handleSaveDialog = item => {
    this.state.selected.selectedYogas = [...this.state.selected.tempSelected];
    this.state.selected.tempSelected = [];
    let jsonArr = JSON.stringify(this.state.selected.selectedYogas);
    localStorage.setItem("routine-" + this.state.selected.title, jsonArr);
    this.handleCloseDialog();
  };
  handleChangeInput = newItem => event => {
    this.setState({
      [newItem]: event.target.value
    });
  };
  removeItem(index) {
    let items = this.state.items;
    localStorage.removeItem("routine-" + items[index].title);
    items.splice(index, 1);
    this.setState({ items });
  }
  handleChecked = (state, id, item) => {
    if (state) {
      let index = item.tempSelected.findIndex(item => item === id);
      if (index === -1) {
        item.tempSelected.push(id);
      }
    } else {
      let index = this.state.selected.tempSelected.findIndex(
        item => item === id
      );
      if (index >= 0) {
        this.state.selected.tempSelected.splice(index, 1);
      }
    }
  };
  render() {
    let empty;
    if (!this.state.items.length) {
      empty = (
        <div
          style={{
            left: 0,
            right: 0,
            margin: "0 auto",
            top: "50%",
            textAlign: "center",
            position: "absolute"
          }}
        >
          {i18next.t('empty_list')}
        </div>
      );
    }
    return (
      <div>
        {empty}
        <div className="routine_list">
          {this.state.items.map((item, index) => {
            return (
              <div className="routine_list__item" key={index}>
                <div
                  className="routine_list__item__title"
                  onClick={() => {
                    this.handleOpenDialog(item);
                  }}
                >
                  {item.title}
                </div>
                <div className="routine_list__item__remove">
                  <Delete
                    onClick={() => {
                      this.removeItem(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Dialog
          fullScreen
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          TransitionComponent={Transition}
        >
          <AppBar className="app_bar_dialog">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleCloseDialog}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className="dialog_title">
                {this.state.selected.title}
              </Typography>
              <Button color="inherit" onClick={this.handleSaveDialog}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className="yoga_list_dialog">
            {this.state.yogaList.map((item, index) => {
              return (
                <div key={index}>
                  <YogaItemCard
                    data={this.state.selected}
                    image={item.yoga_image_b}
                    title={item.yoga_title}
                    subtitle={item.yoga_sanskrit_title}
                    checkbox
                    checked={item.checked}
                    id={item.id}
                    onChange={this.handleChecked}
                  />
                </div>
              );
            })}
          </div>
        </Dialog>
        <Modal open={this.state.openModal} onClose={this.handleCloseModal}>
          <div className="modal">
            <div className="modal__body">
              <TextField
                label={i18next.t('routine_title')}
                value={this.state.newItem}
                onChange={this.handleChangeInput("newItem")}
              />
              <div className="modal__body__add">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.createNewItem}
                >
                  {i18next.t('add')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="">
          <Button variant="fab" color="secondary" onClick={this.openModal} className="add_routine_item">
            <AddIcon />
          </Button>
          {/* <Add  /> */}
        </div>
      </div>
    );
  }
}

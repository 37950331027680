export function postAuth(params) {
  const { login, pass } = params;
  return fetch("/auth.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: `login=${login}&pass=${pass}`
  }).then(resp => {
    return resp.text();
  });
}

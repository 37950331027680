import React from "react";
import { Route, Redirect } from "react-router-dom";

import Levels from "./modules/Levels/route";
import Categories from "./modules/Categories/route";
import Favorites from "./modules/Favorite/route";
import MeditationAudio from "./modules/Meditation/Audio/route";
import MeditationVisual from "./modules/Meditation/Visual/route";
import Routine from "./modules/Routine/route";
import Salutation from "./modules/SunSalutation/route";
import Terms from "./modules/Terms/route";
import YogaItem from "./modules/YogaItem/route";
import User from "./modules/User/route";
import Auth from "./modules/Auth/route";

export function exactRoute(props, route) {
  let currentPath = props.location.pathname;
  let routePath = route.path;
  if (routePath.includes(":")) {
    let nestedPath = currentPath.substring(currentPath.lastIndexOf("/") + 1);
    if (!isNaN(parseInt(nestedPath))) {
      return <RouteWithSubRoutes key={route.path} {...route} />;
    }
  } else if (currentPath === routePath) {
    return <RouteWithSubRoutes key={route.path} {...route} />;
  } else {
    return <h1>Page not Found</h1>;
  }
}

function checkIfAllowed(props, route) {
  let allowed = route.allowed ? [...route.allowed] : [],
      currentLocation = localStorage.getItem("lang");
  if (allowed.includes(currentLocation) || !allowed.length) {
    return (
      <route.component
        {...props}
        {...route}
        to={route.path === route.to ? "" : route.to}
      />
    );
  } else {
    return <Redirect to={route.path === "/levels" ? "" : "/levels"} />;
  }
}

export const RouteWithSubRoutes = route => (
  <Route
    exact={route.isExact}
    path={route.path}
    render={props => checkIfAllowed(props, route)}
  />
);

const MainRoute = [
  {
    path: "/",
    component: Redirect,
    isExact: true,
    to: "/levels"
  }
];

const Meditation = [
  {
    path: "/meditation",
    component: Redirect,
    isExact: true,
    to: "/meditation/visual"
  }
];

export const routes = [
  ...Levels,
  ...Categories,
  ...Favorites,
  ...MeditationAudio,
  ...MeditationVisual,
  ...Routine,
  ...Salutation,
  ...Terms,
  ...YogaItem,
  ...User,
  ...Auth,
  ...Meditation,
  ...MainRoute
];

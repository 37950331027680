import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import YouTube from "react-youtube";
import Loading from "../../components/Loading";
import {
  Videocam,
  FavoriteBorder,
  Favorite,
  ArrowBack
} from "@material-ui/icons";
import { LevelsApi } from "../../apiService";
// import i18next from 'i18next';

function TabContainer({ children, dir }) {
  return (
    <div component="div" dir={dir} className="tabs__item">
      <div className="tabs__item__card">{children}</div>
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

export default class YogaDetails extends Component {
  state = {
    yogaItem: {
      yoga_title: "Yoga Details",
      yoga_step_images: [{ step_image: "" }]
    },
    backPath: "",
    tabsValue: this.props.tabsValue,
    addedInFavorite: false,
    loading: true,
    yoga_id: this.props.match.params.yoga_id,
    openModal: false,
    videoOpt: {
      height: 350,
      width: '100%'
    },
    player: 'youtube'
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.yoga_id !== nextProps.match.params.yoga_id) {
      this.setState({yoga_id: nextProps.match.params.yoga_id},()=>{
        this.props.handleChangeIndex(0);
        this.loadYogaItem();
      })
    }
    console.log(window.innerWidth)
    this.setState({ tabsValue: nextProps.tabsValue });
  }

  componentWillMount() {
    this.props.handleTabsVisibility();
    this.props.handleChangeIndex(0);
    let curFavorites = localStorage.getItem("favorites");
    if (curFavorites.includes(this.state.yoga_id)) {
      this.setState({ addedInFavorite: true });
    } else {
      this.setState({ addedInFavorite: false });
    }
    this.loadYogaItem();
  }

  loadYogaItem() {
    LevelsApi.getYogaDetails(this.state.yoga_id).then(response => {
      console.log("yoga details: ", response);
      if (response.length) {
        this.setState({ yogaItem: response[0], loading: false });
      }
      
      if (this.props.location.state) {
        let from = this.props.location.state.from;
        if (from === "categories") {
          this.setState({ backPath: "/" + from + "/" + response[0].cat_id });
        } else if (from === "levels") {
          this.setState({ backPath: "/" + from + "/" + response[0].level_id });
        } else if (from === "favorite") {
          this.setState({ backPath: "/" + from });
        }
      } else {
        this.setState({ backPath: "/" });
      }
    });
  }

  handleToggleModal = state => {
    this.setState({ openModal: state });
  };

  toggleFavorite(val) {
    this.setState({ addedInFavorite: val }, () => {
      let favorites = JSON.parse(localStorage.getItem("favorites"));
      if (this.state.addedInFavorite) {
        if (!favorites.length) {
          favorites = JSON.stringify([this.state.yogaItem.id]);
          localStorage.setItem("favorites", favorites);
        } else {
          let newFavorites = [...favorites, this.state.yogaItem.id];
          localStorage.setItem("favorites", JSON.stringify(newFavorites));
        }
        // console.log(JSON.parse(localStorage.getItem("favorites")));
      } else {
        let index = favorites.findIndex(
          item => item === this.state.yogaItem.id
        );
        favorites.splice(index, 1);
        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    });
  }
  interpolateHTML(html) {
    return { __html: html };
  }
  render() {
    let cTheme = {
      direction: "ltr"
    };
    let actions;
    if (this.state.addedInFavorite) {
      actions = (
        <div>
          <Videocam />
          <Favorite
            onClick={() => {
              this.toggleFavorite(false);
            }}
            style={{ color: "red" }}
          />
        </div>
      );
    } else {
      actions = (
        <div>
          <Videocam
            onClick={() => {
              this.handleToggleModal(true);
            }}
          />
          <FavoriteBorder
            onClick={() => {
              this.toggleFavorite(true);
            }}
          />
        </div>
      );
    }
    let content, video;
    if (this.state.loading) {
      content = <Loading type="spin" color="#3f51b5" />;
    } else {
      let player;
      if(this.state.yogaItem.video_url.includes('www.youtube.com')){
        player = (
          <YouTube
            videoId={this.state.yogaItem.video_url.substring(
              this.state.yogaItem.video_url.lastIndexOf("=") + 1
            )}
            opts={this.state.videoOpt}
          />
        )
      }else{
        player = (
          <video width="100%" height="350" controls>
            <source src={this.state.yogaItem.video_url} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        )
      }
      content = (
        <div>
          <div className="actions">
            <Link to={this.state.backPath} className="back_link">
              <ArrowBack />
            </Link>
            {actions}
          </div>
          <h4 className="yoga__item__title">
            {this.state.yogaItem.yoga_title}
          </h4>

          <SwipeableViews
            axis={cTheme.direction === "rtl" ? "x-reverse" : "x"}
            index={this.state.tabsValue}
            onChangeIndex={this.props.handleChangeIndex}
          >
            <TabContainer dir={cTheme.direction}>
              <div
                dangerouslySetInnerHTML={this.interpolateHTML(
                  this.state.yogaItem.yoga_benefits
                )}
              />
            </TabContainer>
            <TabContainer dir={cTheme.direction}>
              <div>
                <img
                  src={this.state.yogaItem.yoga_step_images[0].step_image}
                  alt={this.state.yogaItem.yoga_step_images[0].step_image}
                />
              </div>
              <div
                dangerouslySetInnerHTML={this.interpolateHTML(
                  this.state.yogaItem.yoga_step
                )}
              />
            </TabContainer>
            <TabContainer dir={cTheme.direction}>
              <div
                dangerouslySetInnerHTML={this.interpolateHTML(
                  this.state.yogaItem.yoga_cautions
                )}
              />
            </TabContainer>
          </SwipeableViews>
          <Modal
            open={this.state.openModal}
            onClose={() => {
              this.handleToggleModal(false);
            }}
          >
            <div className="modal">
              <div
                className="modal__body"
              >
                {player}
              </div>
            </div>
          </Modal>
        </div>
      );
    }
    return content;
  }
}
